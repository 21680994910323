export default {
    name: "promotions-product",
    props: {
        promotionsProduct: {
            type: [Object, Array],
            required: true
        }
    },
    data() {
        return {}
    }
}
