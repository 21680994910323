

export default {
    name: "promotions-category",
    props: {
        promotionsBrand: {
            type: [Object, Array],
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        onClick() {
            const htmlElement = document.getElementById("html");
            if (htmlElement) {
                htmlElement.classList.remove("hide");
            }
            this.$emit('change-event', this.promotionsBrand);
        }
    }
}
